if (process.env.NODE_ENV ===  'development') {
  window.NODE_ENV = process.env.NODE_ENV;
  import('./main.js');
} else {
  fetch('/env.json')
    .then(resp => resp.text())
    .then((data) => {
      /**
       *
       * @type {string} test, stage, production
       */
      const env = data.slice(8, -2);
      window.NODE_ENV = env;
      import('./main.js');
  })
}


